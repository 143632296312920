import { Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Bar from "../components/header/Bar";
import Footer from "../components/navigation/Footer";
import NavBar from "../components/navigation/NavBar";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";
import BASE_URL from "../services/BASE_URL";
import "./style.css";

export default function QueryPage() {
  const initValues = {
    name: "",
    email: "",
    phone: "",
    city: "",
    bldType: "",
    rptType: "",
    question: "",
  };
  const [formData, setFormData] = useState(initValues);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      // Set loading to true
      setIsLoading(true);

      // Send POST request to the server
      const response = await axios.post(`${BASE_URL}/api/query`, formData);

      // Handle success
      console.log("Response:", response.data);
      setFormData(initValues);
      toast.success("Message sent successfully");
      // You can update your UI or show a success message here
    } catch (error) {
      // Handle error
      console.error("Error submitting the form:", error);
      toast.error("Error while sending message");
      // You can update your UI or show an error message here
    } finally {
      // Set loading back to false, whether the request was successful or not
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "Home > Query";
  return (
    <>
      <Helmet>
        <title>
          Make Query - Contact MatEstimates Today for Your Free Estimate & Get
          in touch with MatEstimates for accurate Material estimation
        </title>
        <meta
          name="description"
          content="MatEstimate is having the expert team for providing accurate and detailed  estimates for material | Contact Us"
        />
      </Helmet>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Contact Us</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>
      <div style={{ maxWidth: "1100px", margin: "0 auto" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <section
              style={{
                backgroundColor: "#f36e21",
                //   padding: "10px",
                borderRadius: "20px",
                margin: "10%",

                // paddingBottom:'10px',
              }}
              className="bg-white dark:bg-gray-900"
            >
              <div class="py-8 lg:py-6 px-4 mx-auto max-w-screen-md">
                <div
                  style={{
                    border: "1px solid white",
                    width: "inherit",
                    maxHeight: "90px",
                    // borderImage: "linear-gradient(to right, green, black)",
                    // borderImageSlice: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <h2 class="mt-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                    Send Your Query
                  </h2>

                  <p
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#f36e21",
                      marginLeft: "10%",
                      marginRight: "10%",
                    }}
                    class="font-light text-center text-black-500 dark:text-gray-400 sm:text-xl"
                  >
                    Drop your Message
                  </p>
                </div>
                <form
                  action="#"
                  onSubmit={handleSubmit}
                  className="space-y-3 p-10"
                >
                  <div>
                    <label
                      for="text"
                      className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Name*
                    </label>
                    <input
                      style={{
                        borderRadius: "40px",
                        border: "1px solid black",
                        padding: "15px",
                      }}
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="Name"
                      required
                    />
                  </div>

                  <div>
                    <label
                      for="email"
                      className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      // className=""
                    >
                      Your email*
                    </label>
                    <input
                      style={{
                        borderRadius: "40px",
                        border: "1px solid black",
                        padding: "15px",
                      }}
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="Input your Email"
                      required
                    />
                  </div>

                  <div>
                    <label
                      for="subject"
                      className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      // className=""
                    >
                      Phone*
                    </label>
                    <input
                      style={{
                        borderRadius: "40px",
                        border: "1px solid black",
                        padding: "15px",
                      }}
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="contact-input block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="Input your Number"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="subject"
                      className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      // className=""
                    >
                      City*
                    </label>
                    <input
                      style={{
                        borderRadius: "40px",
                        border: "1px solid black",
                        padding: "15px",
                      }}
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="contact-input block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="input your City"
                      required
                    />
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      htmlFor="city"
                      className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Building Type*
                    </label>
                    <select
                      style={{
                        borderRadius: "40px",
                        border: "1px solid black",
                        padding: "15px",
                      }}
                      name="bldType"
                      value={formData.bldType}
                      onChange={handleChange}
                      className="contact-input block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      required
                    >
                      <option value="" disabled>
                        Select Building Type
                      </option>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </select>
                  </div>

                  <div class="sm:col-span-2">
                    <label
                      htmlFor="city"
                      className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Report Type*
                    </label>
                    <select
                      style={{
                        borderRadius: "40px",
                        border: "1px solid black",
                        padding: "15px",
                      }}
                      name="rptType"
                      value={formData.rptType}
                      onChange={handleChange}
                      className="contact-input block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      required
                    >
                      <option value="" disabled>
                        Select Report Type
                      </option>
                      <option value="MatRoof">MatRoof</option>
                      <option value="MATRoofα ">MATRoofα </option>
                      <option value="MATSideX">MATSideX</option>
                      <option value="MatPainter">MatPainter</option>
                      <option value="MatGutter">MatGutter</option>
                      <option value="MATConcrete">MATConcrete</option>
                    </select>
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="message"
                      className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      // className=""
                    >
                      Your Query*
                    </label>
                    <textarea
                      style={{
                        borderRadius: "20px",
                        border: "1px solid black",
                        padding: "15px",
                        boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.3)",
                      }}
                      id="message"
                      rows="6"
                      name="question"
                      value={formData.message}
                      required
                      onChange={handleChange}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Leave a QUestion..."
                    ></textarea>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      disabled={isLoading}
                      style={{ backgroundColor: "grey", borderRadius: "30px" }}
                      class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                      {isLoading ? "Sending..." : "Send Query"}
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}
